import request from '../utils/request'

export function verifyPincode(email, pincode) {
    return request.post('./index.php', {
        function: 'verifyPincode',
        email,
        pincode
    })
}

export function loginRefffByEmail(email) {
    return request.post('./index.php', {
        function: 'loginRefffByEmail',
        email
    })
}

export function loginByEmail(email, password, from, type) {
    return request.post('./index.php', {
        function: 'loginByEmail',
        email,
        password,
        from,
        type
    })
}

export function createLoginPinCode(email, type) {
    return request.post('./index.php', {
        function: 'createLoginPinCode',
        email,
        type
    })
}

export function getMyNotes(user_id) {
    return request.post('./index.php', {
        function: 'getMyNotes',
        user_id
    })
}


export function getRefffCardNoteDetail(card_node_id) {
    return request.post('./index.php', {
        function: 'getRefffCardNoteDetail',
        card_node_id
    })
}

export function getRefffArchiveCardNotes(user_id) {
    return request.post('./index.php', {
        function: 'getRefffArchiveCardNotes',
        user_id
    })
}

export function archiveRefffCardNote(user_id, card_note_id) {
    return request.post('./index.php', {
        function: 'archiveRefffCardNote',
        user_id,
        card_note_id
    })
}

export function createRefffAccount(email, mode) {
    return request.post('./index.php', {
        function: 'createRefffAccount',
        email,
        mode
    })
}

export function unarchiveRefffCardNote(archive_id) {
    return request.post('./index.php', {
        function: 'unarchiveRefffCardNote',
        archive_id
    })
}

export function checkArchived(email, card_id) {
    return request.post('./index.php', {
        function: 'checkArchived',
        email,
        card_id
    })
}

export function getNote(id) {
    return request.post('./index.php', {
        function: 'getNote',
        id
    })
}

export function updateNote(params) {
    return request.post('./index.php', {
        function: 'updateNote',
        id: params.id,
        user_id: params.user_id,
        title: params.title,
        content: params.content,
        reviewNote: params.reviewNote,
        labels: params.labels,
    })
}
export function initTags(content) {
    return request.post('./index.php', {
        function: 'initTags',
        content
    })
}
export function removeNote(id) {
    return request.post('./index.php', {
        function: 'removeNote',
        id
    })
}
export function findMyMessages(user_id) {
    return request.post('./index.php', {
        function: 'findMyMessages',
        user_id
    })
}
export function sendMessage(user_id, text) {
    return request.post('./index.php', {
        function: 'sendMessage',
        user_id,
        text
    })
}

export function getMyArticles(params) {
    return request.post('./index.php', {
        function: 'getMyArticles',
        ...params
    })
}
export function getArticle(params) {
    return request.post('./index.php', {
        function: 'getArticle',
        ...params
    })
}

export function updateArticle(params) {
    return request.post('./index.php', {
        function: 'updateArticle',
        ...params
    })
}

export function submitUser(params) {
    return request.post('./index.php', {
        function: 'submitUser',
        ...params
    })
}

export function archiveArticleAndReimind(params) {
    return request.post('./index.php', {
        function: 'archiveArticleAndReimind',
        ...params
    })
}
export function getArchiveArticles(params) {
    return request.post('./index.php', {
        function: 'getArchiveArticles',
        ...params
    })
}

export function getArchiveArticle(params) {
    return request.post('./index.php', {
        function: 'getArchiveArticle',
        ...params
    })
}
export function removeArticle(params) {
    return request.post('./index.php', {
        function: 'removeArticle',
        ...params
    })
}

export function getUserInfo(user_id) {
    return request.post('./index.php', {
        function: 'getUserInfo',
        user_id
    })
}

export function updateUserInfo(params) {
    return request.post('./index.php', {
        function: 'updateUserInfo',
        ...params
    })
}